<template>
  <div class="newsDetails">
    <div class="details_content">
      <div class="news_desc_title">
        {{ detailsData.title }}
      </div>
      <div class="news_desc_time">
        发布时间: {{ detailsData.create_time }}
      </div>
      <div class="news_desc_image">
        <img :src="detailsData.image" alt="">
      </div>
      <div class="news_desc_rich_text" v-html="detailsData.content">

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      id: '',
      detailsData: {}
    }
  },
  created () {
    this.id = this.$route.query.id;
    this.getDetails();
  },
  methods: {
    getDetails () {
      this.$api.getNewsDetail({ id: this.id }).then(res => {
        this.detailsData = res.data;
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.newsDetails {
  padding: 49px 0 58px;
  .details_content {
    width: 1200px;
    min-height: 700px;
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px 0px #e4e9ff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    padding: 64px 34px 50px;
    .news_desc_title {
      font-size: 36px;
      font-weight: 500;
      color: #333333;
      text-align: center;
      margin-bottom: 50px;
    }
    .news_desc_time {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      padding: 18px 0;
      border-top: 1px solid #d8d8d8;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 35px;
    }
    .news_desc_image {
      height: 400px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin-bottom: 35px;
    }
    .news_desc_rich_text {
      font-size: 18px;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>